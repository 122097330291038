import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

import { AbstractApiWrapperService } from '@shared/auth';
import { SimpleUser, StrictHttpResponse, UserInfo, UserService } from '@upscore-mobility-audit/api';

@Injectable({
    providedIn: 'root',
})
export class UpscoreMobilityAuditApiWrapperService extends AbstractApiWrapperService<
    StrictHttpResponse<UserInfo>,
    UserInfo
> {
    constructor(private readonly userService: UserService) {
        super();
    }

    public loginPost(username: string, password: string): Observable<StrictHttpResponse<UserInfo>> {
        const params: {
            body: SimpleUser;
        } = {
            body: {
                username,
                password,
            },
        };

        return this.userService.login$Response(params);
    }

    public getUserInfo(): Observable<UserInfo> {
        return this.userService.userInfo().pipe(
            catchError(error => {
                console.error('Error occurred:', error);

                return throwError(error);
            }),
        );
    }
}
